.whatOrderSection {

    .stillSelectedShow {
        display: flex;
        flex-direction: column;

        .stillContent {
            display: flex;
            flex-direction: row;
            gap: 40px;
            width: 100%;

            div {
                width: calc(100% / 3 - 40px);
                display: flex;
                flex-direction: column;
                input {
                    margin-top: 12px;
                }
            }
        }
    }


    .row {

        .checkbox-container {

            .checkbox-card-input {
                display: none;
            }
        }

        .checkbox-card {
            border: 1px solid #ccc;
            padding: 20px 60px;
            border-radius: 5px;
            text-align: center;
            background-color: #f5f5f5;
            cursor: pointer;
            transition: all 0.3s ease;
            display: block;

            &.checked {
                border-color: #007bff;
                background-color: #e1f0ff;
                box-shadow: 0 2px 5px rgba(0,123,255,.3);
            }
        }
    }
}