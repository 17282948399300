.resetpsw {
    width: 50%;
    margin: 4rem auto 0 auto;
    display: flex;
    flex-direction: column;

    h1 {
        text-align: center;
        color: #005379;
    }
    p {
        text-align: center;
        margin: 0 0 4rem 0;
    }

    input {
        width: 40%;
        margin: 0 auto 20px auto;
        padding: 12px;
    }
    input[type=password] {
        height: 40px;
    }

    button {
        width: 40%;
        margin: 0 auto;
        height: 40px;
        border: 0;
        background-color: #005379;
        color: #FFF;
        cursor: pointer;
        font-weight: bold;
        font-size: 20px;
    }
}