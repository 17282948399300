section {
    >div {
        display: flex;

        &.row {
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        &.column {
            flex-direction: column;
        }

        >div {
            display: flex;
            flex-direction: column;
            width: calc(100% / 2 - 20px);
            margin-top: 12px;
        }
    }

    label {
        margin-bottom: 4px;
        font-weight: bold;
        color: #005379;
    }

    input {
        font-size: 18px;
        border-radius: 6px;
        border: 1px solid #E2E2E2;
    }


    input[type=text],
    input[type=email],
    input[type=number] {
        padding: 12px 8px;
        min-height: 24px;
    }

    input[type=date] {
        width: 140px;
        height: 30px;
        padding: 8px 12px;
    }

    textarea {
        width: 60%;
        border-radius: 6px;
        border: 1px solid #E2E2E2;
        font-size: 18px;
    }

    input[type=checkbox] {
        width: 20px;
        height: 20px;
    }

    input[type=radio] {
        width: 20px;
        height: 20px;
    }


}

.checkbox-pixels {
    color: #005379;
    font-size: 14px;
    margin-left: 30px;
}


.react-datepicker-wrapper {

    button.react-datepicker__close-icon {
        left: 40%;
    }
}