.order-routines {
    width: 70%;
    margin: 0 auto;

    div {
        display: block;
        width: 100%;
    }

    &__header {
        margin-bottom: 2rem;

        h1 {
            text-align: center;
            font-size: 38px;
        }
    }

    &__info {
        margin-bottom: 4rem;

        p {
            margin: 0;
            text-align: center;
            font-style: italic;
        }
    }

    &__content {
        width: 80% !important;
        margin: 0 auto;

        .register-order,
        .treat-order,
        .follow-order {
            border: 4px solid #005379;
            padding: 20px;
            border-radius: 20px;
            margin-bottom: 3rem;

            &__title {
                h2 {
                    text-transform: uppercase;
                }
            }
            &__content {

            }
        }
    }
}