.App-header {
    // background-color: #005379;
    // height: 120px;
}

@font-face {
    font-family: 'Stag';
    src: local('Stag'), url('../fonts/Stag-Semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

* {
    font-family: 'Roboto', sans-serif;
}



h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Stag" !important;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes loadProgress {
    0% { width: 0; }
    100% { width: 100%; }
}

.snowflake {
    position: absolute;
    top: -10px;
    z-index: 9999;
    opacity: 0.9;
    animation: fall linear infinite;
    width: 10px;
    height: 10px;
    background-color: #FFF;
    border-radius: 50%;
}

.announcement {
    width: 50%;
    margin: 0 auto;
    padding-top: 140px;

    &__text {
        width: 100%;
        h1 {
            text-align: center;
            font-size: 3rem;
            color: red;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

@keyframes fall {
    to {
        transform: translateY(180px);
        opacity: 0;
    }
}

.submit-btn {
    margin-top: 4rem;
    border: 0;
    background-color: #005379;
    color: #FFF;
    padding: 6px 22px;
    font-size: 20px;
    cursor: pointer;
    transition: .3s ease;

    &:hover {
        transform: scale(0.9);
        border-radius: 12px;
        transition: .3s ease;
    }

    &.submitting {
        color: transparent;
        background-color: transparent;
        pointer-events: none;
        position: relative;

        &:after {
            content: '';
            border: 2px solid #fff; /* Spinner color */
            border-top: 2px solid #000; /* Spinner track color */
            border-radius: 50%;
            width: 18px;
            height: 18px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            animation: spin 1s linear infinite;
        }
    }
}

.error-message {
    color: red;
    font-weight: bold;
    font-size: 18px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

input[type=text],
input[type=url],
input[type=number],
input[type=email],
input[type=password],
textarea {
    border: 2px solid #005379 !important;
}

.salesSection {
    input {
        pointer-events: none;
        &:focus {
            outline: none;
        }
    }
}




// Modal styling

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
        background: #fff;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        text-align: center;
        width: 50%;

        .buttons {
            display: flex;
            flex-direction: row;
            width: 70%;
            margin: 0 auto;
            justify-content: space-between;

            button {
                // height: 40px;
                padding: 8px 16px;
                border: 0;
                background-color: #005379;
                color: #FFF;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 18px;
                cursor: pointer;
            }
        }
    }
}

.react-datepicker-wrapper {
    .react-datepicker__input-container {
        button {
            margin-left: 40px;
        }
    }
}


// General orders
.generalOrders {

    .innholdpodcast {
        padding-left: 2rem;
    }
}