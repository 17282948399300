.generalOrders {

    .general-order-container {
        flex-direction: column;

        div {
            flex-direction: row;

            label {
                margin-left: 8px;
            }
        }
    }
}