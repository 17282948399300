.innholdsproduksjon {

    .innholdsbestilling {

        >div {
            display: flex;
            flex-direction: row;

            label {
                margin-left: 8px;
            }

        }

        .innholdOtherFree {
            display: flex;
            flex-direction: column;
            margin-top: 3rem;
        }

        .freeTextInfo {
            width: 30%;
            font-style: italic;
            font-size: 16px;
            margin-top: 6px;
        }

        .priceWithoutShowing {
            margin-top: 4rem;

            div {
                flex-direction: column;
            }
        }

        // .innholdTilvalg {
        //     display: flex;
        //     flex-direction: column;
        //     margin-bottom: 4rem;
        //     background-color: #f2f2f2;
        //     width: 100%;
        //     padding: 20px;

        //     h2 {
        //         text-align: left;
        //         margin: 0;
        //     }

        //     .tilvalg {
        //         h4 {
        //             color: #005379;
        //         }

        //         .tilvalgRadio {
        //             div {
        //                 display: flex;
        //                 flex-direction: row;
        //                 align-items: center;
        //                 margin-bottom: 6px;

        //                 a {
        //                     text-decoration: underline;
        //                     color: #000;
        //                     font-weight: bold;
        //                     font-size: 18px;
        //                 }
        //             }
        //         }
        //     }
        // }

        .innholdTilvalg {
            width: 100%;
            display: flex;
            flex-direction: column;

            .tilvalg {
                width: 100%;
                display: flex;
                flex-direction: column;

                .price {
                    display: none;
                }
            }
        }
    }

    .innholdpackages {
        margin-bottom: 4rem;
        width: 100%;
    }

    .priceAndShowing {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        width: 100%;

        >div {
            width: calc(100% / 3 - 40px);
            display: flex;
            flex-direction: column;
        }
    }

    .contentPlacement {
        margin-top: 4rem;
        display: flex;
        flex-direction: column;

        .placementCheckContainer {
            display: flex;
            flex-direction: column;

            label {
                margin-left: 6px;
            }

            div {
                display: flex;
                align-items: center;
            }
        }
        .placementothertext {
            margin-top: 2rem;
        }
    }

    .contentOtherInfo {
        display: flex;
        flex-direction: column;

        .otherInfoContainer {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            gap: 20px;


            .rosenumberContainer {
                width: 30%;

                .contentrosenummer {
                    width: 100%;

                    margin-bottom: 8px;
                }

                .addRosenummerBtn {
                    border: 0;
                    background-color: #005379;
                    padding: 8px 16px;
                    border-radius: 12px;
                    color: #FFF;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }

        .contentrosenummer,
        .contactinfocustomer,
        .contentlocation {
            display: flex;
            flex-direction: column;
            width: calc(100% / 3 - 40px);

            input,
            textarea {
                width: 100%;
            }
        }
    }

}