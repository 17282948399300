.graphicalSection {


    .bannerflowSelected{
        margin-left: 20px;
    }


    .displayFormats {

        .formatsContent {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            >div {
                width: calc(100% / 3);
                margin-bottom: 22px;
            }
        }

        .inputContent {
            
            .otherFormatDisplay {
                display: flex;
                flex-direction: column;
                margin-top: 1rem;
                margin-bottom: 4rem;

                input {
                    max-width: 300px;
                }
            }
        }
    }


    .displayPeriods {
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // justify-content: space-between;

        >div {
            width: calc(100% / 4 - 30px);
            margin-left: 30px;
            margin-bottom: 20px;

            &:first-child {
                margin: 0;
            }
            &:nth-child(5n) {
                margin-left: 0;
            }
            
            >div {
                display: flex;
                flex-direction: column;
            }

            input {
                width: 100%;
            }
        }

        .displayrosenummer {
            margin-bottom: 8px;
        }

        .addRosenummerBtn {
            border: 0;
            background-color: #005379;
            padding: 8px 16px;
            border-radius: 12px;
            color: #FFF;
            font-weight: bold;
            cursor: pointer;
        }
    }

    .paperContentSection {

        .papirannonseTextarea {
            display: flex;
            flex-direction: column;
        }
    }

    .stillingskarusellContentSection {

        .stillingskarusellContent {
            display: flex;
            flex-direction: row;

            .datePicker {
                display: flex;
                flex-direction: column;
                margin-left: 20px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}


.displayContentSection {

    .displayChoice {
        margin-top: 1rem;

        small {
            font-size: 16px;
            margin-left: 26px;
            font-style: italic;
            margin-bottom: 8px;
            display: block;
        }
    }
}


.mn24-checkbox {
    display: flex;
    align-items: center;
    margin-left: 20px;

    div {
        display: flex;
        align-items: center;

        label {
            margin: 0;
        }
    }
}