.profile-container {
	max-width: 800px;
	margin: auto;
	padding: 20px;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	display: flex;
	flex-direction: column;


	.profileInfo {
		width: 100%;
	}

	.profile-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;

		.change-password-btn {
			background-color: #007bff;
			color: white;
			padding: 10px 20px;
			border: none;
			border-radius: 4px;
			cursor: pointer;
		}
	}

	.profile-details {
		display: flex;
		margin-top: 20px;
		
		.profile-picture {
			flex: 1;

			img {
				max-width: 60%;
				border-radius: 8px;
			}
		}
	}

	.profile-info {
		flex: 2;

		p {
			margin-bottom: 10px;
			strong {
				margin-right: 10px;
			}
		}
	}


	.salesStats {
		margin-top: 4rem;

		&__header {
			
		}
	}

}

.password-modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -0%);
	background-color: white;
	padding: 20px;
	width: 300px;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	z-index: 1000;

	.modal-buttons {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
	}
}