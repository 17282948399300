.list-orders {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;


    h1 {
        text-align: center;
    }

    .list-items {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        div {

            h2 {
                text-align: center;
                border-bottom: 2px solid #000;
            }
        }
    }



    .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border-left-color: #09f;
    
        animation: spin 1s ease infinite;
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}