.webSection {

    .radio-cards {
        .radio-container {
            display: flex;
            flex-direction: row;
            gap: 40px;

            .radio-box {
                display: flex;
                flex-direction: column;
                margin: 4rem 0 0;
                width: calc(100% / 2);
                background-color: #f2f2f2;
                border-radius: 12px;
              
                &:first-of-type {
                  margin-left: 0;
                }
              
                .hidden-radio {
                  display: none;
                }
              
                .custom-radio {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                //   border: 2px solid transparent;
                  transition: all 0.3s ease;
                //   max-width: 400px;
                  box-sizing: border-box;
                  height: 100%;
                  cursor: pointer;
                  margin: 0;
              
                  &.selected {
                    border: 2px solid #005379;
                  }
              
                  .content-box {
                    width: 100%;
                    padding: 8px 24px;
                    box-sizing: border-box; // Add this to include padding in the width
                  }
              
                  .title,
                  .sub-title {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin: 0.5rem 0;
                    font-size: 16px;
                  }
                  .sub-title {
                    min-height: 50px;
                  }
            
                  .title {
            
                    h4 {
                        margin: 0;
                        font-size: 22px;
                    }
                  }
                  
                  .checkmarks {
                    margin: 1.5rem 0;
                    min-height: 140px;
                    .checks {
                      margin-bottom: 8px;

                      p {
                        font-size: 14px;
                      }
                    }
                  }
              
                  .price {
                    background-color: #FFFF;
                    border-radius: 12px;
                    width: 40%;
                    padding: 4px 12px;
                    text-align: right;
                    font-size: 18px;
                    position: relative;

                    >small {
                      position: absolute;
                      left: 10px;
                    }
                  }
              
                  .checks {
                    align-items: center;
                    display: flex;
                    flex-direction: row;
              
                    img {
                      width: 20px;
                      height: 20px;
                      margin-right: 8px;
                    }
            
                    p {
                        margin: 0;
                    }
                  }
              
                  .chosen {
                    position: relative;
                    background-color: #054e7a;
                    width: 100%;
                    border-radius: 0 0 8px 8px;
                    padding: 4px 0;
                    text-align: center;
                    font-size: 22px;
                    font-weight: bold;
                    color: #fff;

                    &.selected {
                      background-color: #7EC4BB;
                      color: #054e7a;
                    }
                  }
              
                  .img_url img {
                    display: block;
                    width: 100%;
                    height: 200px;
                    object-fit: fill;
                  }
            
                  .img_small {
                    margin-bottom: 3rem;
                    display: block;
                  }
                }
              }
        }
    }
}