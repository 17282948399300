.main-header {
    display: flex;
    flex-direction: row;
    color: #FFF;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 40px;
    background-color: #005379;
    height: 120px;

    &.fixed-header {
        position: fixed;
        width: 100%;
        z-index: 999;
    }

    .logo {
        width: auto;
        a {
            img {
                height: 60px;
            }
        }
    }

    ul {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        width: 50%;
        margin: 0 auto;

        li {
            list-style-type: none;
            margin-left: 20px;

            &:first-of-type {
                margin-left: 0;
            }

            a {
                color: #FFF;
                text-decoration: none;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }


    button {
      background-color: transparent;
      border: 0;
      color: #FFF;
      margin-left: 2rem;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }    
}