.external-channels {
    margin-top: 4rem;


    .external-top-inputs {
        display: flex;
        flex-direction: row;
        gap: 2rem;

        > div {
            display: flex;
            flex-direction: column;
        }
    }

    .report-message {
        p {
            font-style: italic;
        }
    }

    .external-checkboxes {
        margin-top: 4rem;
    }



    // Facebook

    .facebookSelectedSection {


        .facePeriods {
            margin-top: 4rem;

            h3 {
                color: #005379 !important;
                font-weight: bold;
                text-transform: uppercase;
            }
            
            .periods {
                display: flex;
                flex-direction: row;
                margin-bottom: 4rem;
                flex-wrap: wrap;
                gap: 2rem;

            }

            .datePicker,
            .periodDates,
            .spending,
            .techFee {
                display: flex;
                flex-direction: column;
            }

            .periodDates {
                display: flex;
                flex-direction: row;
                gap: 20px;
            }

            .spending {

                .faceSpending {
                    display: flex;
                    flex-direction: column;
                }
            }

            .techFee {
                .faceTechFee {
                    display: flex;
                    flex-direction: column;
                }
            }

        }

        button.add-period-btn {
            border: 0;
            background-color: #005379;
            color: #FFF;
            font-weight: bold;
            font-size: 16px;
            padding: 6px 12px;
            border-radius: 8px;
            cursor: pointer;
            transition: .3s ease;

            &:hover {
                transform: scale(0.9);
                transition: .3s ease;
            }
        }
    }


    .periods {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 40px;

        >div {
            width: calc(100% / 3 - 80px);
        }

        .datePicker input {
            width: 100%;
        }


        .datePicker,
        .spending,
        .spending div,
        .techFee,
        .techFee div {
            display: flex;
            flex-direction: column;
        }

        .spending .checkbox-container {
            display: flex;
            flex-direction: row;
            gap: 12px;
            margin-top: 12px;
        }
    }

    .periodBtns {
        margin-top: 2rem;

        .addPeriods,
        .removePeriods {
            border: 0;
            padding: 8px 16px;
            font-size: 16px;
            color: #FFF;
            font-weight: bold;
            cursor: pointer;
        }

        .addPeriods {
            background-color: #005379;
        }

        .removePeriods {
            margin-left: 1rem;
            background-color: red;
        }
    }
}