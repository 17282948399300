.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);
    z-index: 999;

    .modal-content {
        width: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #005379;
        z-index: 999;
        height: 50vh;

        h1 {
            text-align: center;
            margin-top: 2rem;
            color: #FFF;

            b {
                text-decoration: underline;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            width: 50%;
            margin: 0 auto;
            margin-top: 4rem;

            .editUsernameField {
                background-color: #f2f2f2;
                border: 0;
                pointer-events: none;

                &:focus {
                    outline: none;
                }
            }

            div {
                display: flex;
                flex-direction: row;
                margin-top: 1rem;
                gap: 20px;

                input {
                    width: calc(100% / 2);
                }

                &:last-of-type {
                    margin-bottom: 1rem;
                }
            }

            button {
                margin-top: 2rem;
                padding: 12px 0;
                font-size: 20px;
                cursor: pointer;
                border: 0;
                color: #FFF;
                font-weight: bold;
                transition: .3s ease;

                &:hover {
                    transform: scale(0.9);
                    transition: .3s ease;
                }

                &:last-of-type {
                    margin-top: 1rem;
                }
            }

            .saveUser {
                background-color: green;
            }

            .closeModal {
                background-color: red;
            }
        }
    }
}