.webSection {

    
    .campAddon,
    .websiteAddon {
        margin-top: 4rem;
        background-color: #f2f2f2;
        padding: 10px;

        h3 {
            text-align: center;
            margin: 0;
        }

        small {
            text-align: center;
            display: block;
            margin-top: 20px;
        }

        .choices {
            margin-top: 1.2rem;

            .choice {
                margin-bottom: 12px;
                // border: 2px solid #054e7a;
                padding: 12px;
                position: relative;

                input {
                    position: absolute;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    cursor: pointer;
                }

                label {
                    display: block;
                    padding: 20px;
                    cursor: pointer;
                    border: 2px solid #054e7a;
                    border-radius: 12px;

                    &.selected {
                        box-shadow: 2px 2px 4px 4px #054e7a;
                    }
                }
            }
        }
    }


    .weblandings {
        margin-top: 4rem;

        .weblandingstitles {

            .weblandinginfo {
                background-color: #f2f2f2;
                padding: 12px;
                font-style: italic;
                font-weight: bold;
            }
        }

        .weblandingcontent {

        }

        .weblandingurl {
            display: flex;
            flex-direction: column;
            margin-top: 2rem;

            input {
                height: 40px;
                max-width: 30%;
                padding: 6px;
            }
            small {
                margin-top: 12px;
                display: block;
                font-style: italic;
            }
        }
    }
}