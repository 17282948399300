.usermanagement {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    h2 {
        text-align: center;
    }

    .CreateMessage {
        background-color: green;
        padding: 20px;
        color: #FFF;
        font-size: 22px;
        border-radius: 8px;
    }

    .userSection {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 60%;
        margin: 4rem auto 0 auto;
        justify-content: space-between;


        .createUserSection {
            background-color: #f2f2f2;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            width: 275px;

            .new-user-btn {
                border: 0;
                max-width: 200px;
                padding: 8px;
                background-color: #005379;
                color: #FFF;
                font-size: 18px;
                font-weight: bold;
                flex-grow: 0;
                flex-shrink: 1;
                flex-basis: auto;
                border-radius: 8px;
                cursor: pointer;
            }

            .newUserForm {
                display: flex;
                flex-direction: column;
                margin-top: 2rem;
                align-items: center;

                input {
                    margin-top: 1rem;
                    height: 40px;
                    padding: 0 10px;
                    width: 100%;
                }

                button {
                    margin-top: 1rem;
                    border: 0;
                    background-color: #005379;
                    padding: 4px 8px;
                    color: #FFF;
                    border-radius: 8px;
                    font-size: 18px;
                    width: 100%;
                    cursor: pointer;

                    &.cancelUser {
                        background-color: red;
                    }
                }
            }
        }

        ul {
            width: 60%;
            margin: 0 auto;
            display: block;
            flex-grow: 1;

            li {
                text-decoration: none;
                list-style-type: none;
                padding: 12px;
                position: relative;

                &:nth-child(even) {
                    background-color: #FFF;
                }
                &:nth-child(odd) {
                    background-color: #f2f2f2;
                }

                button {
                    border: 0;
                    padding: 4px 8px;
                    border-radius: 8px;
                    color: #FFF;
                    position: absolute;
                    right: 12px;
                    cursor: pointer;

                    &.btn-delete {
                        background-color: red;
                        margin-right: 110px;
                    }
                    &.btn-change {
                        background-color: #005379;
                    }
                }
            }
        }


        table {
            width: 100%;

            thead {
                background-color: #005379;
                color: #FFF;
            }

            tbody {

                tr {
                    height: 40px;
                    border-left: 2px solid #005379;
                    
                    &:nth-child(even) {
                        background-color: #f2f2f2;
                    }
                    td {
                        padding: 4px 8px;

                        .changeUser {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;

                            button {
                                border: 0;
                                padding: 4px 16px;
                                border-radius: 8px;
                                cursor: pointer;
                                color: #FFF;
                                font-weight: bold;
                            }
    
                            button.btn-delete {
                                background-color: red;
                            }
                            button.btn-change {
                                background-color: #005379;
                            }
                        }
                        
                    }
                }
            }
        }

        .usersDisplay {
            width: 74%;

            .searchUsers {
                margin-bottom: 4px;
            }
        }
    }
}