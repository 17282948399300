.statistics {
    width: calc(100% - 280px);
    padding: 0 40px;
    padding-bottom: 4rem;
    .row {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: space-between;
        width: 100%;
        margin-top: 2rem;

        div {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0;

            h2 {
                color: #000;
            }
            
            .number {
                height: 80px;
                width: 80px;
                background-color: #000;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                p {
                    color: #FFF;
                    margin: 0;
                    font-size: 20px;
                    font-weight: bold;
                }
            }
        }

        .diffOrders {
            background-color: #f2f2f2;
            width: 100%;

            canvas {
                max-height: 400px;
            }
        }
    }

    .column {
        display: flex;
        flex-direction: column;
    }

    .forms-sent {
        background-color: #f2f2f2;
    }

    .numSalesPersons {
        background-color: #f2f2f2;
    }
}



.filterStats {
    margin-top: 4rem;

    .filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;

        select {
            border: 0;
            padding: 12px;
            font-size: 18px;
        }

        button {
            background-color: #005379;
            border: 0;
            // border-radius: 12px;
            font-size: 18px;
            padding: 12px;
            color: #FFF;
            font-weight: bold;
            cursor: pointer;
        }
    }

    .viewFilterStats {
        width: 80%;
        margin: 0 auto;
    }

    // TECH FEE CALCULATION ROW
    .calcTechFee {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        gap: 20px;
        align-items: center;
        margin-top: 2rem;

        div {
            width: 100%;
            background-color: #005379;
            text-align: center;

            h3,
            p {
                color: #FFF;
            }
            h3 {
                font-size: 1rem;
            }

            p {
                font-weight: bold;
                font-size: 20px;
            }
        }
    }

    .numFormsTech {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 4rem auto 0 auto;

        .totalForms {
            background-color: #f2f2f2;
            padding: 20px;
            text-align: center;
            width: 100%;

            p {
                font-size: 18px;
                font-weight: bold;
                text-decoration: underline;
            }
        }

        .usersTech {
            margin-top: 2rem;
            background-color: #f2f2f2;
            width: 100%;
            padding: 20px;

            h3,
            p {
                text-align: center;
                margin: 0;
            }

            ul {
                // margin-top: 2rem;
                width: 90%;
                margin: 2rem auto 0 auto;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 20px;

                li {
                    list-style-type: none;
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 12px;
                    padding: 12px;
                    background-color: #fff;
                    text-transform: capitalize;
                    width: calc(100% / 3 - 40px);

                    b {
                        margin-left: 12px;
                    }
                }
            }

        }
    }
}