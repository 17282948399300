.landingPage {

    .landingpages {
        display: flex;
        flex-direction: column;

        input {
            width: 400px;
            padding: 8px;
            margin-bottom: 8px;
        }

        .addLandingBtn {
            width: 20%;
            padding: 8px 12px;
            background-color: #005379;
            color: #FFF;
            font-weight: bold;
            border: 0;
            cursor: pointer;
        }

        .landingInfoSpan {
            p {
                font-size: 16px;
                font-style: italic;
                text-decoration: underline;
                margin: 0 0 20px 0;
                font-weight: bold;
            }
        }
    }
}