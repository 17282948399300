.loginPrompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;

    p {
        margin: 0;
    }

    .loginForm {
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        input {
            width: 60%;
            height: 40px;
            margin-bottom: 1rem;
            border: 2px solid #005379;
            padding: 8px;
            position: relative;
        }
    }

    .loginBtnMsg {
        width: 60%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
            display: flex;
            flex-direction: row;
            gap: 40px;
            width: calc(100% / 2);
        }

        button {
            width: 20%;
            background-color: #005379;
            border: 0;
            color: #FFF;
            padding: 12px 0;
            border-radius: 12px;
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            cursor: pointer;
            transition: .2s ease;
            width: 100%;

            &:hover {
                background-color: rgb(18, 116, 18);
                transition: .2s ease;
            }

            &.restorepasswordbtn {
                background-color: red;
            }

            &.toggleforgotbtn {
                background-color: red;
            }
        }

        p {
            font-size: 20px;
            margin-top: 1.5rem;
            font-weight: bold;
        }
    }
}