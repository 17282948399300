.formBottomSection {

    .briefManusField {
        width: 100%;
    }

    .brief {

        small {
            width: 50%;
            font-style: italic;
            margin-top: 1rem;
            font-size: 16px;
        }

        p.materiell {
            font-weight: bold;
            font-size: 18px;
        }
    }

    .korrektur {
        small {
            font-style: italic;
            font-size: 16px;
        }

        .korrSameSeller {
            margin-top: 2rem;
        }

        .korrSameSeller,
        .korrNotNeeded {
            flex-direction: row;

            label {
                margin-left: 8px;
            }
        }
    }
}