.clientSection {

    .rammeavtale {
        display: flex;
        align-items: center;
        justify-content: center;

        label {
            font-size: 20px;
            margin-left: 4px;
        }
    }
}

.ordrenummer {
    div {
        display: flex;
        flex-direction: column;
    }
}