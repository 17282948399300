.additionalServices {
    margin: 2rem 0;
  
    .content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 1.5rem;
      padding: 1rem;
  
      .flex {
        background-color: #f9f9f9;
        border: 1px solid #e0e0e0;
        padding: 1rem;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: background-color 0.3s ease, border-color 0.3s ease;
        cursor: pointer;
        
        &:hover {
          background-color: #e6f7ff;
        }
  
        &.selected {
          background-color: #e6f7ff;
          border-color: #1890ff;
        }
  
        .container {
          display: flex;
          align-items: center;
  
          span {
            font-size: 1rem;
            font-weight: 500;
            color: #333;
          }
        }
  
        .price {
          font-size: 1rem;
          color: #666;
          font-weight: 500;
          white-space: nowrap;
          margin-left: 1rem;
        }
  
        .info-icon {
          font-size: 1.25rem;
          color: #007bff;
        }
      }
    }
  
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;
  
      .modal-content {
        background: white;
        padding: 2rem;
        border-radius: 8px;
        width: 90%;
        max-width: 600px;
        position: relative;
  
        h3 {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 1rem;
        }
  
        .modal-close {
          position: absolute;
          top: 10px;
          right: 15px;
          font-size: 1.5rem;
          cursor: pointer;
        }
  
        .modal-sub-table {
          table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 1rem;
  
            th, td {
              padding: 0.5rem;
              border: 1px solid #ddd;
              text-align: left;
            }
          }
  
          small {
            display: block;
            font-size: 0.85rem;
            margin-top: 0.5rem;
          }
        }
  
        .modal-some-packages {
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;
        
          .modal-some-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 1rem;
            border-bottom: 1px solid #eee;
  
            .modal-some-description {
              flex: 1;
              text-align: left;
              h4 {
                font-size: 1.2rem;
                font-weight: bold;
              }
              p {
                font-size: 1rem;
                margin-top: 0.25rem;
                color: #666;
              }
            }
  
            .modal-some-price {
              font-size: 1.2rem;
              font-weight: bold;
              color: #333;
              white-space: nowrap;
              margin-left: 2rem;
              text-align: right;
            }
          }
        }
      }
    }
  }
  