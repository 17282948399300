.salesFormContainer {
    margin: 0 auto;
    padding-top: 6rem;
    padding-bottom: 6rem;

    @media only screen and (min-width: 481px) and (max-width: 767px) {
        width: 90%;
    }

    @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 80%;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 80%;
    }

    @media only screen and (min-width: 1200px) {
        width: 60%;
    }

    h2 {
        text-align: center;
        color: #005379;
        border-bottom: 2px solid #E2E2E2;
        padding-bottom: 12px;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 4rem;
    }

    
    .whatOrderSection {
        .row {
            width: 50%;
            margin: 0 auto;
            div {
                display: flex;
                flex-direction: row;
                justify-content: center;
            }
        }
    }
}