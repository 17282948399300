.controlPanel {

    nav {
        width: 280px;
        background-color: #005379;
        display: flex;
        flex-direction: column;
        padding: 20px;
        height: 100vh;


        * {
            color: #FFF;
        }

        ul {
            padding: 0;


            li {
                list-style-type: none;
                margin-top: 12px;
                font-size: 18px;
    
                a {
                    text-decoration: none;
                }
            }
        }
    }


    .control-panel-view {
        width: calc(100% - 280px);

        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 20px;
            padding: 0 20px;
        }

        .numUsers,
        .numFormsSent {
            width: calc(100% / 2);
            max-height: 160px;

            h2 {
                text-align: center;
            }

            p {
                text-align: center;
                font-size: 22px;
                font-weight: bold;
            }
        }

        .numUsers {
            background-color: #a18585;
        }
        .numFormsSent {
            background-color: #e07a7a;
        }    


        .listPDF {
            background-color: #f2f2f2;
            margin-top: 1rem;
            padding: 20px 0;
            margin-left: 20px;
            margin-right: 20px;
            ul {
                li {
                    list-style-type: none;

                    a {
                        text-decoration: none;
                        color: #000;
                        font-size: 18px;

                        i {
                            color: red;
                            margin-right: 12px;
                            font-size: 18px;
                            
                        }
                    }
                }
            }
        }
    }

}